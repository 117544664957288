const USER_ROLE = {
  User: 0,
  ReportReader: 1,
  GlobalAdministrator: 2,
  ConfigurationEditor: 3,
  ScopedAdministrator: 4
}

const userAttributeDataType = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  DROPDOWN: 'DROPDOWN',
  ICON: 'ICON'
}

const currencySymbols = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'Fr',
  BMD: '$',
  BND: '$',
  BOB: 'Bs.',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'Br',
  BZD: '$',
  CAD: '$',
  CDF: 'Fr',
  CHE: 'CHE',
  CHF: 'Fr',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '$',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fr',
  DKK: 'kr',
  DOP: '$',
  DZD: 'د.ج',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GHS: '₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'Fr',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JMD: '$',
  JOD: 'د.ا',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'Fr',
  KPW: '₩',
  KRW: '₩',
  KWD: 'د.ك',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'L',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'ل.د',
  MAD: 'د.م.',
  MDL: 'L',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'P',
  MRO: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: 'ر.ع.',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: 'ر.ق',
  RON: 'lei',
  RSD: 'дин.',
  RUB: '₽',
  RWF: 'Fr',
  SAR: 'ر.س',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'Sh',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  SYP: '£',
  SZL: 'L',
  THB: '฿',
  TJS: 'ЅМ',
  TMT: 'm',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₺',
  TTD: '$',
  TWD: 'NT$',
  TZS: 'Sh',
  UAH: '₴',
  UGX: 'Sh',
  USD: '$',
  USN: 'USN',
  USS: 'USS',
  UYI: 'UYI',
  UYU: '$',
  UZS: 'лв',
  VEF: 'Bs F',
  VND: '₫',
  VUV: 'Vt',
  WST: 'T',
  XAF: 'Fr',
  XAG: 'oz t',
  XAU: 'oz t',
  XBA: 'XBA',
  XBB: 'XBB',
  XBC: 'XBC',
  XBD: 'XBD',
  XCD: '$',
  XDR: 'SDR',
  XFU: 'XFU',
  XOF: 'Fr',
  XPD: 'oz t',
  XPF: 'Fr',
  XPT: 'oz t',
  XTS: 'XTS',
  XXX: 'XXX',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK'
}

const responsibleTypeStringToScopeArrayPropertyObj = {
  department: 'responsibleDepartments',
  officeLocation: 'responsibleOfficeLocations',
  country: 'responsibleCountries'
}

const defaultLabelToKey = {
  Department: 'department',
  'Job Title': 'jobTitle',
  'Office Location': 'officeLocation',
  Country: 'country',
  'Employee Hire Date': 'employeeHireDate',
  Birthday: 'birthday'
}

const FOCUS_PEOPLE_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  REJECTED: 0
}

export {
  USER_ROLE,
  userAttributeDataType,
  currencySymbols,
  responsibleTypeStringToScopeArrayPropertyObj,
  defaultLabelToKey,
  FOCUS_PEOPLE_STATUS
}
