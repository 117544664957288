import { Text } from 'office-ui-fabric-react'
import * as React from 'react'

const TextSubtitle2Stronger = ({ children, style, block = true, ...props }: React.ComponentProps<typeof Text>) => (
  <Text block={block} {...props} style={{ fontSize: 16, fontWeight: 700, color: '#313131', ...style }}>
    {children}
  </Text>
)

export default TextSubtitle2Stronger
