import mixpanel from 'mixpanel-browser'
import * as moment from 'moment'
import Cookies from 'universal-cookie'
import { SUBSCRIPTION_LICENSE_TYPE } from '../constants/tenantConstants'

const cookies = new Cookies()

const initMixpanel = () => {
  try {
    mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN, {
      track_pageview: false,
      debug: process.env.NODE_ENV === 'development',
      persistence: 'localStorage'
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelAliasUser = user => {
  try {
    if (user) {
      mixpanel.alias(user._id)
    }
  } catch (err) {
    console.log(err)
  }
}
const mixpanelIdentifyUser = (user, inTeams) => {
  const region = cookies.get('x-region') || localStorage.getItem('x-region')

  try {
    if (user) {
      mixpanel.identify(user._id)
      const mixPanelRegister = {
        userID: user._id,
        role: user.role,
        subscriptionStatus: user.tenant.subscriptionStatus,
        dayFromSignupDate: moment().diff(moment(user.createdAt), 'days'),
        platform: inTeams ? 'teams' : 'web',
        environment: 'live',
        'Tenant Graph Size': user.tenant.graphTotalUserCount,
        'Tenant license count': user.tenant.licensedUserCount,
        'Tenant Name': user.tenant.Id,
        'Is Manager': user.isManager,
        goalsActive: !user.tenant.settings.goalDisabled,
        reviewsActive: !user.tenant.settings.reviewDisabled,
        surveysActive: !user.tenant.settings.surveyDisabled,
        recognitionsActive: !user.tenant.settings.recognitionDisabled,
        feedbacksActive: !user.tenant.settings.feedbackDisabled,
        tasksActive: !user.tenant.settings.taskDisabled,
        meetingsActive: !user.tenant.settings.meetingDisabled,
        region: region,
        viewportW: window.innerWidth,
        viewportH: window.innerHeight,
        'Subscription License Type': user.tenant.subscriptionLicenseType || ''
      }
      mixpanel.register(mixPanelRegister)
    }
  } catch (err) {
    console.log(err)
  }
}

const mixpanelSetUserProperty = (key, value) => {
  try {
    mixpanel.people.set({
      [key]: value
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelSwitchToDemoEnvironment = () => {
  try {
    mixpanel.register({
      environment: 'demo'
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelSwitchToLiveEnvironment = () => {
  try {
    mixpanel.register({
      environment: 'live'
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelPageViewEvent = (page, properties?) => {
  mixpanel.track_pageview({ page: page, ...properties })
}

const mixpanelButtonClickedEvent = (buttonName, openedFrom, properties?) => {
  try {
    const urlName = window.location.href.split('#').pop()
    mixpanel.track('Button Clicked', {
      buttonName: buttonName,
      openedFrom: openedFrom,
      pageUrl: urlName,
      ...properties
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelSubmitClickedEvent = (buttonName, openedFrom, properties?) => {
  try {
    const urlName = window.location.href.split('#').pop()
    mixpanel.track('Submit Clicked', {
      buttonName: buttonName,
      openedFrom: openedFrom,
      pageUrl: urlName,
      ...properties
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelOpenClickedEvent = (buttonName, openedFrom, properties?) => {
  try {
    const urlName = window.location.href.split('#').pop()
    mixpanel.track('Open Clicked', {
      buttonName: buttonName,
      openedFrom: openedFrom,
      pageUrl: urlName,
      ...properties
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelLogoutEvent = () => {
  try {
    mixpanel.track('Logout')
    resetMixpanel()
  } catch (err) {
    console.log(err)
  }
}

const resetMixpanel = () => {
  mixpanel.reset()
}

const mixpanelCustomEvent = (eventName, properties = {}) => {
  try {
    mixpanel.track(eventName, {
      ...properties
    })
  } catch (err) {
    console.log(err)
  }
}

const mixpanelErrorEvent = (error, page, properties) => {
  try {
    mixpanel.track('Error', {
      error: error,
      page: page,
      ...properties
    })
  } catch (err) {
    console.log(err)
  }
}

export {
  initMixpanel,
  resetMixpanel,
  mixpanelButtonClickedEvent,
  mixpanelSubmitClickedEvent,
  mixpanelOpenClickedEvent,
  mixpanelAliasUser,
  mixpanelIdentifyUser,
  mixpanelLogoutEvent,
  mixpanelPageViewEvent,
  mixpanelSwitchToDemoEnvironment,
  mixpanelSwitchToLiveEnvironment,
  mixpanelCustomEvent,
  mixpanelErrorEvent,
  mixpanelSetUserProperty
}
