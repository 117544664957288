// #region Imports
import * as React from 'react'
import axios from 'axios'
import { GroupedSettings, MODULES, ModuleSetting } from '../types/settings'
import * as moment from 'moment'
// #endregion

// #region Types
interface TenantSettingsContextType {
  groupedSettings: GroupedSettings | null
  loading: boolean
  error: string | null
  refreshSettings: () => Promise<void>
  isModuleHidden: (module: MODULES) => boolean
  isUpgradePageShown: (module: MODULES) => boolean
  getModuleSettings: (module: MODULES) => ModuleSetting | null
}
// #endregion

// #region Context
const TenantSettingsContext = React.createContext<TenantSettingsContextType | undefined>(undefined)

const SETTINGS_TTL = 3 // In minute

// #endregion

// #region Provider Component
export const TenantSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // #region State
  const [groupedSettings, setGroupedSettings] = React.useState<GroupedSettings | null>(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)
  const [validUntil, setValidUntil] = React.useState<Date | null>(null)
  // #endregion

  // #region Data Fetching
  const fetchSettings = async () => {
    try {
      setLoading(true)
      setError(null)

      const response = await axios.get('/api/tenantSettings/getGroupedTenantSettings')
      setGroupedSettings(response.data as GroupedSettings)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch settings')
      console.error('Error fetching tenant settings:', err)
    } finally {
      setLoading(false)
    }
  }

  // #endregion

  // #region Helpers

  const checkIfSettingsAreExpired = () => {
    if (moment().isAfter(validUntil)) {
      setValidUntil(moment().add(SETTINGS_TTL, 'minutes').toDate())
      fetchSettings()
    }
  }

  // #endregion

  React.useEffect(() => {
    fetchSettings()
  }, [])

  // #region Hooks

  const getModuleSettings = (module: MODULES) => {
    checkIfSettingsAreExpired()
    return groupedSettings?.[module]
  }

  const isModuleHidden = (module: MODULES) => {
    return !getModuleSettings(module)
  }

  const isUpgradePageShown = (module: MODULES) => {
    return !!getModuleSettings(module)?.isUpgradePageShown
  }

  const refreshSettings = async () => {
    await fetchSettings()
  }
  // #endregion

  // #region Render
  return (
    <TenantSettingsContext.Provider
      value={{
        groupedSettings,
        loading,
        error,
        refreshSettings,
        isModuleHidden,
        isUpgradePageShown,
        getModuleSettings
      }}
    >
      {children}
    </TenantSettingsContext.Provider>
  )
  // #endregion
}
// #endregion

// #region Hook
export const useTenantSettings = () => {
  const context = React.useContext(TenantSettingsContext)
  if (context === undefined) {
    throw new Error('useTenantSettings must be used within a TenantSettingsProvider')
  }
  return context
}
// #endregion
