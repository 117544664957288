import { Text } from 'office-ui-fabric-react'
import * as React from 'react'

const TextSubtitle2 = ({ children, style, ...props }: React.ComponentProps<typeof Text>) => (
  <Text block {...props} style={{ fontSize: 16, fontWeight: 600, color: '#313131', ...style }}>
    {children}
  </Text>
)

export default TextSubtitle2
