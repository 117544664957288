import UserStore from './userStore'
import WindowStore from './windowStore'
import ModalStore from './modalStore'
import GroupingStore from './groupingStore'
import NavStore from './navStore'
import LocationStore from './locationStore'
import UserPageStore from './userPageStore'

export default {
  userStore: new UserStore(),
  windowStore: new WindowStore(),
  modalStore: new ModalStore(),
  groupingStore: new GroupingStore(),
  navStore: new NavStore(),
  locationStore: new LocationStore(),
  userPageStore: new UserPageStore()
}

/**
 * consumed in index.tsx
 * import stores from './stores/index'
 *
 */
