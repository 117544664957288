import { Text } from 'office-ui-fabric-react'
import * as React from 'react'

const TextRegular = ({ children, style, block = true, ...props }: React.ComponentProps<typeof Text>) => (
  <Text block={block} {...props} style={{ fontSize: 14, fontWeight: 400, color: '#313131', ...style }}>
    {children}
  </Text>
)

export default TextRegular
