import { Text } from 'office-ui-fabric-react'
import * as React from 'react'

const TextSmall = ({ children, style, ...props }: React.ComponentProps<typeof Text>) => (
  <Text block {...props} style={{ fontSize: 12, fontWeight: 400, color: '#313131', ...style }}>
    {children}
  </Text>
)

export default TextSmall
