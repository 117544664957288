import * as moment from 'moment'
import axios from 'axios'

import create from 'zustand'

interface DateFilter {
  dateOption: string
  startDate: Date
  dueDate: Date
  quarterOptions: Array<any>
  quarters: Array<any>
}

interface DateFilterStoreState {
  currentModule: string
  recognitions: DateFilter
  goals: DateFilter
  goalGroups: DateFilter
  meetingGoals: DateFilter
  userGoals: DateFilter
  tasks: DateFilter
  feedback: DateFilter
  feedbackSummarize: DateFilter
  reviews: DateFilter
  surveys: DateFilter
  changeDateOption: (module: string, dateOption: string) => void
  setCurrentModule: (module: string) => void
  setQuarters: ({ quarterOptions, quarters }: { quarterOptions: Array<any>; quarters: Array<any> }) => void
  setStartAndDueDate: (dates: { startDate: Date; dueDate: Date }, callback: any) => void
  setQuartersForSummarize: ({ quarterOptions, quarters }: { quarterOptions: Array<any>; quarters: Array<any> }) => void
  setDateOption: (dateOption: string, callback: any) => void
  setDateOptionWithSpecificModule: (dateOption: string, module: string, callback: any) => void
  setCurrentModuleWithCallBack: (module: string, callback: any) => void
  setDateOptionWithoutUpdate: (dateOption: string, callback: any) => void
  setStartAndDueDateWithSpecificModule: (
    dates: { startDate: Date; dueDate: Date },
    module: string,
    callback: any
  ) => void
  setStartAndDueDateForUndefinedDatesWithSpecificModule: (module: string, callback: any) => void
  setStartAndDueDateForUndefinedDates: (callback: any) => void
  setStartAndDueDateWithoutUpdate: (dates: { startDate: Date; dueDate: Date }, callback: any) => void
  setDateWithName: (whichDate: string, date: any, callback: any) => void
  setDates: (startDate: Date, dueDate: Date, callback: any) => void
  setDateWithNameSpecificModule: (whichDate: string, date: Date, module: string, callback: any) => void
  setInitialDateFilter: (determinedDates: any, callback: any) => void
}

const useDateFilterStore = create<DateFilterStoreState>((set, get) => ({
  currentModule: null,
  recognitions: {
    dateOption: 'All recognitions',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  tasks: {
    dateOption: 'All tasks',
    startDate: moment().subtract(3, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  feedback: {
    dateOption: 'All feedback',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  goals: {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  },
  meetingGoals: {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  },
  goalGroups: {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  },
  userGoals: {
    dateOption: 'Active goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  },
  reviews: {
    dateOption: 'All reviews',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  feedbackSummarize: {
    dateOption: 'All feedback',
    startDate: moment().subtract(3, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  surveys: {
    dateOption: 'All surveys',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  },
  setCurrentModule: m => {
    //@ts-ignore
    set({ currentModule: m })
  },
  changeDateOption: (module: string, dateOption: string) => {
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, dateOption } as DateFilter })
  },
  setQuarters: ({ quarterOptions, quarters }: { quarterOptions: Array<any>; quarters: Array<any> }) => {
    const curr = get().currentModule
    const currentModule = get()[curr]

    if (quarterOptions && quarters) {
      //@ts-ignore
      set({
        [curr]: {
          ...currentModule,
          quarterOptions: quarterOptions,
          quarters: quarters
        } as DateFilter
      })
    }
  },
  setQuartersForSummarize: ({ quarterOptions, quarters }: { quarterOptions: Array<any>; quarters: Array<any> }) => {
    //@ts-ignore
    set({
      feedbackSummarize: {
        ...get().feedbackSummarize,
        quarterOptions: quarterOptions,
        quarters: quarters
      } as DateFilter
    })
  },
  setDateOption: (dateOption: string, cb: any) => {
    const m = get().currentModule
    const currentModule = get()[m]
    //@ts-ignore
    set({ [m]: { ...currentModule, dateOption } })
    axios.post('/api/common/updateDeterminedDates', { dateOption: dateOption, currentModule: m })
    cb && cb()
  },
  setDateOptionWithSpecificModule: (dateOption: string, module: string, cb: any) => {
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, dateOption } as DateFilter })
    cb && cb()
  },
  setCurrentModuleWithCallBack: (module: string, cb: any) => {
    //@ts-ignore
    set({ currentModule: module })
    cb && cb()
  },
  setDateOptionWithoutUpdate: (dateOption: string, callback: any) => {
    const m = get().currentModule
    const currentModule = get()[m]
    //@ts-ignore
    set({ [m]: { ...currentModule, dateOption } as DateFilter })
    callback && callback()
  },
  setStartAndDueDate: (dates: { startDate: Date; dueDate: Date }, callback: any) => {
    const m = get().currentModule
    const currentModule = get()[m]
    //@ts-ignore
    set({ [m]: { ...currentModule, startDate: dates.startDate, dueDate: dates.dueDate } as DateFilter })
    axios.post('/api/common/updateDeterminedDates', {
      startDate: dates.startDate,
      dueDate: dates.dueDate,
      currentModule: m
    })
    callback && callback()
  },
  setStartAndDueDateWithSpecificModule: (dates: { startDate: Date; dueDate: Date }, module: string, cb: any) => {
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, startDate: dates.startDate, dueDate: dates.dueDate } as DateFilter })
    cb && cb()
  },
  setStartAndDueDateForUndefinedDatesWithSpecificModule: (module: string, callback: any) => {
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, startDate: undefined, dueDate: undefined } as DateFilter })
    callback && callback()
  },
  setStartAndDueDateForUndefinedDates: (cb: any) => {
    const m = get().currentModule
    const currentModule = get()[m]
    //@ts-ignore
    set({ [m]: { ...currentModule, startDate: null, dueDate: null } as DateFilter })
    axios.post('/api/common/updateDeterminedDates', { startDate: null, dueDate: null, currentModule: m })

    cb && cb()
  },
  setStartAndDueDateWithoutUpdate: (dates: { startDate: Date; dueDate: Date }, callback: any) => {
    const module = get().currentModule
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, startDate: dates.startDate, dueDate: dates.dueDate } as DateFilter })
    callback && callback()
  },
  setDateWithName: (whichDate: string, date: any, callback: any) => {
    if (whichDate === 'startDate') {
      date = moment(date).startOf('day').toDate()
    }
    if (whichDate === 'dueDate') {
      date = moment(date).endOf('day').toDate()
    }

    const module = get().currentModule
    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, [whichDate]: date } as DateFilter })

    if (whichDate === 'startDate') {
      axios.post('/api/common/updateDeterminedDates', { startDate: date, currentModule: module })
    }
    if (whichDate === 'dueDate') {
      axios.post('/api/common/updateDeterminedDates', { dueDate: date, currentModule: module })
    }
    callback && callback()
  },
  setDates: (startDate: Date, dueDate: Date, callback: any) => {
    const module = get().currentModule
    const currentModule = get()[module]
    startDate = moment(startDate).startOf('day').toDate()
    dueDate = moment(dueDate).endOf('day').toDate()
    //@ts-ignore
    set({ [module]: { ...currentModule, startDate, dueDate } as DateFilter })
    callback && callback()
  },
  setDateWithNameSpecificModule: (whichDate: string, date: Date, module: string, callback: any) => {
    if (whichDate === 'startDate') {
      date = moment(date).startOf('day').toDate()
    }
    if (whichDate === 'dueDate') {
      date = moment(date).endOf('day').toDate()
    }

    const currentModule = get()[module]
    //@ts-ignore
    set({ [module]: { ...currentModule, [whichDate]: date } as DateFilter })
    callback && callback()
  },
  setInitialDateFilter: (determinedDates: any, callback: any) => {
    if (determinedDates.goal !== undefined) {
      //@ts-ignore
      set({
        goals: {
          ...get().goals,
          startDate:
            determinedDates?.goal?.startDate !== undefined && determinedDates?.goal?.startDate !== null
              ? moment(determinedDates.goal.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.goal?.dueDate !== undefined && determinedDates?.goal?.dueDate !== null
              ? moment(determinedDates.goal.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.goal?.dateOption || 'All goals'
        } as DateFilter
      })
    }

    if (determinedDates.feedback !== undefined) {
      //@ts-ignore
      set({
        feedback: {
          ...get().feedback,
          startDate:
            determinedDates?.feedback?.startDate !== undefined && determinedDates?.feedback?.startDate !== null
              ? moment(determinedDates.feedback.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.feedback?.dueDate !== undefined && determinedDates?.feedback?.dueDate !== null
              ? moment(determinedDates.feedback.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.feedback?.dateOption || 'All feedback'
        } as DateFilter
      })
    }

    if (determinedDates.review !== undefined) {
      //@ts-ignore
      set({
        reviews: {
          ...get().reviews,
          startDate:
            determinedDates?.review?.startDate !== undefined && determinedDates?.review?.startDate !== null
              ? moment(determinedDates.review.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.review?.dueDate !== undefined && determinedDates?.review?.dueDate !== null
              ? moment(determinedDates.review.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.review?.dateOption || 'All reviews'
        } as DateFilter
      })
    }

    if (determinedDates.survey !== undefined) {
      //@ts-ignore
      set({
        surveys: {
          ...get().surveys,
          startDate:
            determinedDates?.survey?.startDate !== undefined && determinedDates?.survey?.startDate !== null
              ? moment(determinedDates.survey.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.survey?.dueDate !== undefined && determinedDates?.survey?.dueDate !== null
              ? moment(determinedDates.survey.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.survey?.dateOption || 'All surveys'
        } as DateFilter
      })
    }

    if (determinedDates.task !== undefined) {
      //@ts-ignore
      set({
        tasks: {
          ...get().tasks,
          startDate:
            determinedDates?.task?.startDate !== undefined && determinedDates?.task?.startDate !== null
              ? moment(determinedDates.task.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.task?.dueDate !== undefined && determinedDates?.task?.dueDate !== null
              ? moment(determinedDates.task.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.task?.dateOption || 'All tasks'
        } as DateFilter
      })
    }

    if (determinedDates.recognition !== undefined) {
      set({
        recognitions: {
          ...get().recognitions,
          startDate:
            determinedDates?.recognition?.startDate !== undefined && determinedDates?.recognition?.startDate !== null
              ? moment(determinedDates.recognition.startDate).startOf('day').toDate()
              : undefined,
          dueDate:
            determinedDates?.recognition?.dueDate !== undefined && determinedDates?.recognition?.dueDate !== null
              ? moment(determinedDates.recognition.dueDate).startOf('day').toDate()
              : undefined,
          dateOption: determinedDates?.recognition?.dateOption || 'All recognitions'
        } as DateFilter
      })
    }
  }
}))

export { useDateFilterStore }
