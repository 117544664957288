import * as React from 'react'
import axios from 'axios'
import * as moment from 'moment'
import { whoIs } from './relationHelper'
import { currencySymbols, USER_ROLE } from '../constants/userConstants'
import { Icon } from 'office-ui-fabric-react'
import { mixpanelLogoutEvent } from './mixPanelHelper'
import Cookies, { CookieSetOptions } from 'universal-cookie'
import Swal from 'sweetalert2'
import { TextRegular } from '../components/text'
import i18n from 'i18next'
import { SUBSCRIPTION_LICENSE_TYPE, SUBSCRIPTION_STATUS } from '../constants/tenantConstants'

const getUser = (userUPN, userStore) =>
  new Promise((resolve, reject) => {
    const config: any = {
      params: {
        upn: userUPN,
        relationType: whoIs(userStore, userUPN)
      }
    }
    axios
      .get(`/api/common/getUser`, config)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })

const isInUserPages = () => window.location.hash.includes('user')

const isCreatedAtBiggerThenUserLastMeetingStartDate = (item, moduleName, user) => {
  if (moduleName === 'feedback') {
    return moment(item.submitDate).isAfter(user?.lastOneOnOne && user.lastOneOnOne?.startDateTime)
  }
  return moment(item.createdAt).isAfter(user?.lastOneOnOne && user.lastOneOnOne?.startDateTime)
}

const getUserAttributeValue = ({
  value,
  dataType,
  currency,
  isBirthday = false
}: {
  value: string
  dataType: 'DATE' | 'NUMBER' | 'TEXT' | 'ICON' | 'DROPDOWN'
  currency?: string
  isBirthday?: boolean
}) => {
  if (dataType === 'DATE') {
    if (value) {
      if (isBirthday) {
        return moment(value).format('Do MMMM')
      }
      return moment(value).format('Do MMMM YYYY')
    } else return value
  }
  if (dataType === 'NUMBER') {
    return value
  }
  if (dataType === 'TEXT') {
    return value
  }
  if (dataType === 'ICON') {
    return <Icon iconName={value} />
  }
  if (dataType === 'DROPDOWN') {
    return value
  }
  if (dataType === 'CURRENCY' && currency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    })

    return formatter.format(Number(value))
  }
  return value
}

const getUserAddress = user => {
  if (user.UPN) {
    return user.UPN
  } else if (user.mail) {
    return user.mail
  } else if (user.userPrincipalName) {
    return user.userPrincipalName
  }
}

const clearCookie = async () => {
  try {
    const cookies = new Cookies()
    const cookieOptions: CookieSetOptions = {}

    if (process.env.NODE_ENV === 'production') {
      cookieOptions.domain = '.teamflect.com'
    }
    cookies.remove('x-region', cookieOptions)
    await axios.get('/api/authorize/clear-cookie', {
      withCredentials: true
    })
  } catch (e) {
    console.log(e)
  }
}

const logOut = ({ userStore, notifyError = false }) => {
  try {
    mixpanelLogoutEvent()
    const cookies = new Cookies()
    if (userStore.platform === 'web') {
      axios.post('/api/common/logOutWeb').then(async response => {
        await clearCookie()
        let url = window.location.hostname
        if (window.location.port !== '') {
          url = url + ':' + window.location.port
        }

        let fullUrl = 'https://' + url + '/getstarted'
        if (notifyError) {
          fullUrl = fullUrl + '?hasError=true&errorCode=oopsError'
        }

        setTimeout(() => {
          window.location.href = fullUrl
        }, 100)
      })
    } else if (userStore.platform === 'outlook') {
      axios.post('/api/common/logOutOutlook').then(async response => {
        await clearCookie()

        let fullUrl = window.location.origin + '/getstarted'
        if (notifyError) {
          fullUrl = fullUrl + '?hasError=true&errorCode=oopsError'
        }

        setTimeout(() => {
          window.location.href = fullUrl
        }, 300)
      })
    } else if (userStore.platform === 'teams') {
      axios.post('/api/common/logOutTeams').then(response => {
        let fullUrl = window.location.origin + '/getstarted?inTeams=true'
        if (notifyError) {
          fullUrl = fullUrl + '&hasError=true&errorCode=oopsError'
        }
        setTimeout(() => {
          window.location.href = fullUrl
        }, 100)
      })
    }
    localStorage.clear()
    window.Intercom('shutdown')
  } catch (e) {
    console.log(e)
  }
}

const deleteAccount = ({ userStore, successCb = () => {} }) => {
  localStorage.clear()
  const cookies = new Cookies()
  axios.post('/api/common/deleteAccount').then(async response => {
    if (response.status === 200) {
      successCb()
      localStorage.clear()
      if (userStore.platform === 'web') {
        await clearCookie()

        let url = window.location.hostname
        if (window.location.port !== '') {
          url = url + ':' + window.location.port
        }
        setTimeout(() => {
          window.location.href = 'https://' + url + '/getstarted'
        }, 100)
      } else if (userStore.platform === 'outlook') {
        await clearCookie()

        setTimeout(() => {
          window.location.href = window.location.origin + '/getstarted'
        }, 300)
      } else if (userStore.platform === 'teams') {
        setTimeout(() => {
          window.location.href = window.location.origin + '/getstarted?inTeams=true'
        }, 100)
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      })
    }
  })
}

const getRole = (role, responsibleDepartments) => {
  if (role == USER_ROLE.GlobalAdministrator) {
    return i18n.t('OnboardPage_globalAdministrator', 'Global Administrator')
  } else if (role == USER_ROLE.ReportReader) {
    let role = i18n.t('OnboardPage_reportReader', 'Report Reader')
    if (responsibleDepartments && responsibleDepartments.length > 0) {
      role = role + ' (' + responsibleDepartments.toString() + ')'
    }
    return role
  } else if (role == USER_ROLE.ConfigurationEditor) {
    return i18n.t('OnboardPage_configurationEditor', 'Configuration Editor')
  } else if (role == USER_ROLE.ScopedAdministrator) {
    let role = i18n.t('OnboardPage_scopedAdministrator', 'Scoped Administrator')
    if (responsibleDepartments && responsibleDepartments.length > 0) {
      role = role + ' (' + responsibleDepartments.toString() + ')'
    }
    return role
  } else return i18n.t('OnboardPage_user', 'User')
}

const getRoleOnboardingDescription = role => {
  const baseText = i18n.t('OnboardPage_youAreAllSetAsA', "You're all set as a ")

  const roleTextMap = {
    [USER_ROLE.GlobalAdministrator]: {
      role: i18n.t('OnboardPage_globalAdministrator', 'Global Administrator'),
      suffix: i18n.t(
        'OnboardPage_onTeamflectGivingYouFullControl',
        ' on Teamflect, giving you full control to manage users and settings seamlessly!'
      )
    },
    [USER_ROLE.ReportReader]: {
      role: i18n.t('OnboardPage_reportReader', 'Report Reader'),
      suffix: ''
    },
    [USER_ROLE.ConfigurationEditor]: {
      role: i18n.t('OnboardPage_configurationEditor', 'Configuration Editor'),
      suffix: ''
    },
    default: {
      role: i18n.t('OnboardPage_user', 'User'),
      suffix: ''
    }
  }

  const { role: roleText, suffix } = roleTextMap[role] || roleTextMap.default

  return (
    <TextRegular>
      {baseText}
      <TextRegular style={{ fontWeight: 'bold', display: 'inline' }}>{roleText}</TextRegular>
      {suffix}
    </TextRegular>
  )
}

const getOnboardingDescriptionForTenantSubscription = ({
  subscriptionStatus,
  subscriptionLicenseType,
  licensedUserCount
}) => {
  if (subscriptionStatus === SUBSCRIPTION_STATUS.FREE) {
    return i18n.t(
      'OnboardPage_youAreCurrentlyOnTheStarterPlanForUpTo10Users',
      "You're currently on the {{plan}} plan for up to 10 users, giving you access to starter features! If you're loving Teamflect, you can upgrade anytime later to unlock its full potential! 🚀",
      {
        plan: 'Starter'
      }
    )
  }

  if (subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
    const subscriptionMap = {
      [SUBSCRIPTION_LICENSE_TYPE.PROFESSIONAL]: 'Professional',
      [SUBSCRIPTION_LICENSE_TYPE.ESSENTIAL]: 'Essential'
    }

    const subscription = subscriptionMap[subscriptionLicenseType]

    return i18n.t(
      'OnboardPage_youAreCurrentlyOnThePaidPlan',
      "You're currently on the {{subscription}} plan for up to {{licensedUserCount}} users 🚀",
      {
        subscription,
        licensedUserCount
      }
    )
  }
}

export {
  getUser,
  isInUserPages,
  isCreatedAtBiggerThenUserLastMeetingStartDate,
  getUserAttributeValue,
  getUserAddress,
  logOut,
  deleteAccount,
  getRole,
  getRoleOnboardingDescription,
  getOnboardingDescriptionForTenantSubscription
}
