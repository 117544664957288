import * as React from 'react'
import { useState, createContext, useContext } from 'react'

export interface IReviewContext {
  reviewList: any[]
  expandedReviewList: any[]
  expandedReviewId: string
  mode: string
  setReviewList: (reviewList: any[]) => void
  setExpandedReviewList: (reviewList: any[]) => void
  setExpandedReviewId: (id: string) => void
  setMode: (mode: string) => void
  clearState: () => void
}

export const ReviewContext = createContext<IReviewContext>({
  reviewList: [],
  expandedReviewList: [],
  expandedReviewId: '',
  mode: '',
  setReviewList: () => {},
  setExpandedReviewList: () => {},
  setExpandedReviewId: () => {},
  setMode: () => {},
  clearState: () => {}
})

const ReviewContextProvider = props => {
  const [reviewList, setReviewList] = useState([])
  const [expandedReviewList, setExpandedReviewList] = useState([])
  const [expandedReviewId, setExpandedReviewId] = useState('')
  const [mode, setMode] = useState('')

  const clearState = () => {
    setMode('')
    setExpandedReviewId('')
    setReviewList([])
    setExpandedReviewList([])
  }

  return (
    <ReviewContext.Provider
      value={{
        reviewList,
        expandedReviewList,
        expandedReviewId,
        mode,
        setReviewList,
        setExpandedReviewList,
        setExpandedReviewId,
        setMode,
        clearState
      }}
    >
      {props.children}
    </ReviewContext.Provider>
  )
}

const useReview = () => {
  return useContext(ReviewContext)
}

export { ReviewContextProvider, useReview }
