import { SUBSCRIPTION_STATUS, SUBSCRIPTION_LICENSE_TYPE } from '../../src/constants/tenantConstants'

const setProperty = (key, value) => {
  //@ts-ignore
  if (window.clarity) {
    //@ts-ignore
    window.clarity('set', key, value)
  }
}

const claritySetUserProperty = (key, value) => {
  setProperty(key, value)
}

const setClarityUserProperties = userData => {
  if (userData) {
    const setObj = {}

    setObj['userID'] = userData._id
    if (userData?.tenant?.subscriptionLicenseType) {
      setObj['Subscription License Type'] = userData.tenant.subscriptionLicenseType
    }
    if ([SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.FREE].includes(userData.tenant.subscriptionStatus)) {
      setObj['Subscription status'] =
        userData.tenant.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ? 'Paid' : 'Free'
    }
    if (userData?.tenant?.subscriptionLicenseType === SUBSCRIPTION_LICENSE_TYPE.PROFESSIONAL) {
      setObj['Professional Subscription Date'] = userData.tenant.subscriptionDetails?.startDate
    }

    Object.keys(setObj).forEach(key => {
      claritySetUserProperty(key, setObj[key])
    })
  }
}

export { claritySetUserProperty, setClarityUserProperties }
